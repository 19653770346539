import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import App from './App';
import PageNotFound from './PageNotFound';
import HtmlRules from './Rules';
import WarRules from './WarRules'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='rules/war' element={<WarRules />} />
            <Route path='/rules' element={<HtmlRules />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
