import Navbar from "./Navbar";

function WarRules() {


    return (
        <>
            <Navbar />
            <div className="Rules" id="Container">
                <h1 className="Rules-title">War Rules</h1>
                <p className="Rules-intro">
                    Rules and scenarios can be perceived with different interpretations.
                    Thus rules and punishments can and will be flexible and applied differently to different situations, 
                    disagreements are entirely understood, but respect that moderation staff have the final verdict.
                    <br/><br/>
                    Ignorance of rules is not an excuse for breaking them. Utilising loopholes in the rules is not allowed.
                </p>
                <hr/>
                <h2>Definitions</h2>
                <p className="Rules-intro">
                    Skirmish: a fight between 2 or more participants of the war. These fights can happen anywhere at any time.<br/>
                    Siege: a siege is an attack on a town, where the attacker uses the plugin to attempt to occupy them and take money.<br/>
                    RP: roleplay<br/>
                    Casus belli: a reason to go to war with someone<br/>
                    Combatant: someone who has participated in the war at any point in time (has fought in the war, has supplied fighters, or has spied for one side)<br/>
                    Non-combatant: someone who has never participated in the war (never has fought anyone, not directly supplying the war effort, and has stated their peaceful intentions)<br/>
                    Mercenary: a mercenary is a third party combatant that is paid for by either side to join the war.
                </p>
                <h2>Declaring War</h2>
                <ol>
                    <li>To declare war on a nation/town, you must have a valid RP reason. War declarations without a valid reason will be voided.
                        <ul>
                            <li>Examples of some valid reasons include: diplomatic incidents, conquest, religious reasons, etc. To verify your war reason, please open a ticket and consult the staff in the ticket.</li>
                        </ul>
                    </li>
                    <li>After a war declaration is made, 24 hours must pass before any fighting happens.
                        <ul>
                            <li>Neither skirmishes nor sieges are allowed before this time ends.</li>
                        </ul>
                    </li>
                    <li>Post any war declarations in the #war-declaration channel in the Rhaya discord.</li>
                    <li>Allies are required to post their involvement in the war declaration thread and they must wait 24 hours after their own declaration announcement before participating.</li>
                    <li>Mercenaries must be paid at least $8000 and provide proof of payment; a screenshot in the war declaration thread.</li>
                    <li>When a mercenary joins a war, the town that they are a part of is entered into the war as well.</li>
                    <li>All participants in a war must join their side as soon as possible after the war is created in plugin.</li>
                    <li>Towns/nations must wait two weeks after the end of their last war before joining the attacking side of a war or declaring a new war.</li>
                    <li>Mercenaries must wait four weeks before joining another war or conflict.</li>
                </ol>
                <h2>War Rules</h2>
                <ol start="10">
                    <li>In order to partake in any form of war, you must join the built in war plugin on the correct side.</li>
                    <li>Once you join the war plugin, you are allowed to leave, however this will negate you from rejoining the war meaning you cannot participate in that same war again in any form.
                        <ul>
                            <li>This applies to mercenaries as well. We heavily encourage mercenaries to bargain their worth between two combating entities. Rejoining the same conflict under a different contract is not allowed.</li>
                        </ul>
                    </li>
                    <li>Minute-manning is not allowed. These are players who join shortly before a war and whose sole purpose on the server is to be a combatant during wars.</li>
                    <li>You cannot move your homeblock or /t spawn after declaring a war.</li>
                    <li>You are not allowed to break any treaties/agreements with other nations or towns without having an RP justification.</li>
                    <li>Leaving the war plugin before the war is over does not guarantee that you won't be subject to the consequences of losing.</li>
                    <li>You cannot use Alt accounts in any way during a war.</li>
                    <li>If a mercenary town is successfully sieged, the town will be knocked out of the war and the mercenary will not be able to fight on either side of the war. The mercenary town is removed from the war once their town siege time ends.</li>
                    <li>You are not able to enforce ridiculous war reparations, (more than 50% of what the losing side has in assets + cash). If the losing side does not pay back any war reparations, this is a cause for war.</li>
                    <li>Players must be active for a minimum of two weeks before joining the war.</li>
                    <li>If you defeat an opposing town in a siege and they have a port/train station, their port/train station can no longer be used by the opposing side for the remainder of the war.</li>
                </ol>
                <h2>Siege Rules</h2>
                <ol start="21">
                    <li>Sieges on inactive towns (1 month without anyone in the town logging on) will be given instant victory. The victor has full control in the peace deal.</li>
                    <li>Defenders can not trap the homechunk and the direct pathway to the homechunk, the direct pathway to the homechunk cannot be a maze either. </li>
                    <li>
                    The T-Spawn and any surrounding chunks cannot be modified in the 24 hours leading up to a siege.
                    </li>
                    <li>
                    A town’s homeblock should have a generous amount of room. The entrance to the homeblock should be accessible through one door for attackers. 
                    </li>
                    <li>
                    Firing explosives at a town before the siege time is not allowed.
                    </li>
                    <li>
                    Defenders are able to place one door every two chunks, with a maximum of three doors in the pathway to the homechunk.
                    </li>                
                    <li>
                    The path to the homeblock has to be easily found and cannot be a 1x2 tunnel, it must at least be 3x3 (3 blocks wide, 3 blocks tall). 
                    </li>
                    <li>
                    Trapping players with no way out is not allowed. Any traps have to either kill the player quickly or have an easily accessible exit. 
                    </li>
                    <li>
                    Staff will check each siege site before a siege begins in order to ensure siege rules are being followed.
                    </li>
                </ol>
                <h2>Combatants and Non-Combatants</h2>
                <ol start="30">
                    <li>
                    All players who are part of a nation/town in the war are subject to killing. If a player is a non-combatant, you must warn them and give them time to either decide if they want to fight or leave peacefully. 
                    </li>
                    <li>
                    You can kill people that are providing weapons/gear to the opposite side of the war. This is only if you have clear proof of them providing it to the other side. It is recommending you either get screenshots of them in-game dropping weapons/gear, or get clear proof of conversations saying they are providing/producing weapons and gear.
                    </li>
                    <li>
                    Staff have the final say on killings. They can decide if a kill is legal or not. If you feel that you’ve been killed unreasonably, please open a ticket.
                    </li>
                    <li>
                    As a non-combatant it is your responsibility to avoid any behaviour that could be viewed as that of a combatant. It is the right of a combatant to kill any opposing nation/town members who show up to their territory. 
                    </li>
                    <li>
                    Players must be in the server for a minimum of two weeks before a war is declared in order to participate.
                    </li>
                </ol>
                <h2>General Rules</h2>
                <ol start="35">
                    <li>We reserve the right to moderate and punish any behaviour which could be seen as circumventing and breaking the spirit of the rules. </li>
                    <li>No delaying wars for unreasonable amounts of time or for unreasonable reasons.</li>
                    <li>
                    Staff members must be non biased, and can not moderate anything relating to the war if they are a participant. This will be enforced by the rest of staff.
                    </li>
                    <li>
                    Players who join the server for the sole purpose of engaging in a war that has already been declared will not be allowed to participate even after being in the server for two weeks.. This may lead to further punishment as well.
                    </li>
                    <li>
                    All homeblocks in a war will be checked during the 24 hour period before a war, and will need to be given approval before a siege.
                    </li>
                    <li>
                    Spectators are not allowed to participate in any way, shape, or form. If you are a spectator in a warzone you may be collateral damage. A spectator being killed does not serve as a Casus belli.
                    </li>
                </ol>
            </div>
        </>
    );
}

export default WarRules