import React from 'react';
import logo from './assets/logo.png'
import { Link } from 'react-router-dom';
import './Navbar.css';
import ServerIpButton from './ServerIpButton.js';

const Navbar = () => {
    return (
        <nav className='navbar'>
            <ServerIpButton />
            <div className='navbar-container'>
            <Link to='/'>
                <img src={logo} alt="Rhaya Logo" height="50px" />
            </Link>
            <div className='navbar-menu'>
                <ul className='navbar-list'>
                    <li className='navbar-item'>
                        <Link to='/'>Home</Link>
                    </li>


                    <li className='navbar-item dropdown'>
                        <Link className='dropbtn' to='/rules'>Rules</Link>
                        <div className='dropdown-content'>
                            <Link to='/rules/war'>War</Link>
                        </div>
                    </li>





                    <li className='navbar-item'>
                        <a id='External-link' href='https://rhayarealm.miraheze.org/wiki/Main_Page' target='_blank' rel='noreferrer'>Wiki</a>
                    </li>
                    <li className='navbar-item'>
                        <a id='External-link' href='https://discord.gg/xY46YPgGG7' target='_blank' rel='noreferrer'>Discord</a>
                    </li>
                </ul>
            </div>
            </div>
        </nav>
    );
};

export default Navbar;