import './About.css';

function About() {
    return (
        <div className="About">
                <h1>What is the Realm of Rhaya?</h1>
                <p id='About-text'>
                    The Realm of Rhaya is a world-building and geopolitics Minecraft server that is currently open to the public. 
                    The server is set in a fantasy world called Rhaya, where players can create their own nations, form alliances, and wage wars. 
                    The server is designed to be a sandbox for players to create their own stories and adventures.
                </p>
                <h2>How do I join?</h2>
                <p id='About-text'>
                    You are able to join using the IP: <code>play.realmofrhaya.com</code>.<br/>
                    If you wish to participate in world-building and politics, the majority of events are logged and actioned in our 
                    <a id='External-link' href='https://discord.gg/xY46YPgGG7' target='_blank' rel='noreferrer'> Discord </a>
                    server. We highly suggest you join it for updates and more!
                </p>
        </div>
    );
}

export default About;